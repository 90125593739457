import * as styles from './StandardGroup.module.scss'

import React from 'react'

import CardImage from 'components/cards/CardImage'

interface Props {
  cards: readonly {
    name: string | null
    set: string | null
    id: string | null
    emphasized: boolean | null
  }[]
}

export const StandardGroup: React.FC<Props> = (props) => {
  const { cards } = props

  return (
    <div className={styles.container}>
      {cards.map((card, index) =>
        card.name ? (
          <CardImage
            key={index}
            set={card.set ?? undefined}
            id={card.id ?? undefined}
            emphasized={card.emphasized ?? false}
            canFlip
          >
            {card.name}
          </CardImage>
        ) : null,
      )}
    </div>
  )
}
