import * as styles from './Timestamp.module.scss'

import React, { useMemo } from 'react'

import { parseDuration } from 'src/utils/parseDuration'

import { usePlayerState } from 'src/components/podcast/PlayerContext'

interface Props {
  children: string
  start: string
}

const Timestamp: React.FC<Props> = (props) => {
  const { children, start } = props

  const time = useMemo(() => parseDuration(start), [start])

  const { setSeekToSeconds } = usePlayerState()

  return (
    <div
      className={styles.container}
      onClick={() => {
        setSeekToSeconds && setSeekToSeconds(time)
      }}
    >
      <div className={styles.startTime}>
        <div className={styles.playIcon}>▶</div>
        {start}
      </div>
      <div className={styles.label}>{children}</div>
    </div>
  )
}

export default Timestamp
