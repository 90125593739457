import * as styles from './Controls.module.scss'

import React, { useCallback } from 'react'
import { clamp } from 'lodash'

import { SmallButton } from 'components/controls'

import Checkbox from 'components/forms/Checkbox'

interface Props {
  pick: number
  setPick: React.Dispatch<React.SetStateAction<number>>
  packsPickedFrom: {
    pack: number
    pick: number
    cards: { name: string; emphasized?: boolean }[]
  }[]
  showPicks: boolean
  setShowPicks: React.Dispatch<React.SetStateAction<boolean>>
}

const Controls: React.FC<Props> = (props) => {
  const { pick, setPick, packsPickedFrom, showPicks, setShowPicks } = props

  const shiftPick = useCallback(
    (amount: number) => {
      setPick((value) => clamp(value + amount, 0, packsPickedFrom.length - 1))
    },
    [packsPickedFrom.length, setPick],
  )

  return (
    <div className={styles.container}>
      <div className={styles.spacer} />

      <div className={styles.navigation}>
        <SmallButton onClick={() => shiftPick(-1)} disabled={pick === 0}>
          &larr;
        </SmallButton>

        <select
          className={styles.pickSelect}
          onChange={(event) => setPick(parseInt(event.currentTarget.value))}
          value={pick.toString()}
        >
          {packsPickedFrom.map((pack, index) => (
            <option key={index} value={index}>
              Pack {pack.pack + 1}, Pick {pack.pick + 1}
            </option>
          ))}
        </select>

        <SmallButton
          onClick={() => shiftPick(1)}
          disabled={pick === packsPickedFrom.length - 1}
        >
          &rarr;
        </SmallButton>
      </div>

      <div className={styles.options}>
        <label className={styles.showPicks}>
          <Checkbox
            checked={showPicks}
            onChange={(event) => setShowPicks(event.currentTarget.checked)}
          />{' '}
          Show Picks
        </label>
      </div>
    </div>
  )
}

export default Controls
