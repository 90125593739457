import * as styles from './Cards.module.scss'

import React from 'react'
import { compact } from 'lodash'

import { DraftViewer } from 'src/components/draft-viewer/DraftViewer'

import { GridDraftGroup } from './GridDraftGroup'
import { StandardGroup } from './StandardGroup'
import { Decklist } from './Decklist'

// TODO: Add explicit GraphQL types to simplify this.

interface Props {
  cards: {
    groups: ReadonlyArray<{
      heading: string | null
      subheading: string | null
      display: string | null
      cards: ReadonlyArray<{
        name: string | null
        set: string | null
        id: string | null
        emphasized: boolean | null
      } | null> | null
      subgroups: ReadonlyArray<{
        pack: number | null
        pick: number | null
        column: string | null
        cards: ReadonlyArray<{
          name: string | null
          set?: string | null
          id?: string | null
          emphasized: boolean | null
        } | null> | null
      } | null> | null
    } | null> | null
  }
}

export const Cards: React.FC<Props> = (props) => {
  const { cards } = props

  if (cards.groups == null) {
    return null
  }

  return (
    <div className={styles.container}>
      {cards.groups.map((group, index) => {
        if (group == null || (group.cards == null && group.subgroups == null)) {
          return null
        }

        const groupCards = compact(group.cards)

        return (
          <div className={styles.group} key={index}>
            {(group.heading != null || group.subheading != null) && (
              <div>
                {group.heading && (
                  <h3
                    className={styles.groupHeading}
                    dangerouslySetInnerHTML={{ __html: group.heading }}
                  />
                )}

                {group.subheading && (
                  <div className={styles.groupSubheading}>
                    {group.subheading}
                  </div>
                )}
              </div>
            )}
            {group.display === 'grid-draft' ? (
              <GridDraftGroup cards={groupCards} />
            ) : group.display === 'interactive-draft' ? (
              <DraftViewer packsPickedFrom={group.subgroups as any} />
            ) : group.display === 'decklist' ? (
              <Decklist cards={group as any} />
            ) : (
              <StandardGroup cards={groupCards} />
            )}
          </div>
        )
      })}
    </div>
  )
}
