import * as styles from './Decklist.module.scss'

import React, { useMemo } from 'react'
import { compact } from 'lodash'

import GenericDecklist from 'src/components/decklists/Decklist'

interface Props {
  cards: {
    subgroups: ReadonlyArray<{
      column: string | null
      cards: ReadonlyArray<{
        name: string | null
        set: string | null
        id?: string | null
      } | null> | null
    } | null> | null
  }
}

export const Decklist: React.FC<Props> = (props) => {
  const { cards } = props

  const maindeck = useMemo(() => {
    return compact(cards.subgroups)
      .filter((subgroup) => subgroup?.column != 'sideboard')
      .map((column) => compact(column.cards))
  }, [cards])

  const sideboard = useMemo(() => {
    const column = compact(cards.subgroups).find(
      (subgroup) => subgroup?.column === 'sideboard',
    )

    return column != null ? compact(column?.cards) : undefined
  }, [cards])

  // TODO: Add explicit GraphQL types to simplify and improve types here.

  return (
    <div className={styles.container}>
      <GenericDecklist
        maindeck={maindeck as any}
        sideboard={sideboard as any}
      />
    </div>
  )
}
