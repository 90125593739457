import * as styles from './GridDraftGroup.module.scss'

import React from 'react'

import CardImage from 'components/cards/CardImage'
import CardHover from 'src/components/cards/CardHover'

interface Props {
  cards: readonly {
    name: string | null
    set: string | null
    id: string | null
    emphasized: boolean | null
  }[]
}

export const GridDraftGroup: React.FC<Props> = (props) => {
  const { cards } = props

  return (
    <div className={styles.container}>
      <div className={styles.cards}>
        {cards.map((card, index) => {
          if (card.name == null) {
            return null
          }
          return (
            <CardHover
              key={index}
              card={card.name}
              set={card.set ?? undefined}
              id={card.id ?? undefined}
            >
              <CardImage
                set={card.set ?? undefined}
                id={card.id ?? undefined}
                emphasized={card.emphasized ?? false}
                canFlip
              >
                {card.name}
              </CardImage>
            </CardHover>
          )
        })}
      </div>
    </div>
  )
}
