import React from 'react'

import Timestamp from './Timestamp'

interface Props {
  timestamps:
    | readonly ({
        label: string | null
        time: string | null
      } | null)[]
    | null
}

export const Timestamps: React.FC<Props> = (props) => {
  const { timestamps } = props

  if (timestamps == null) {
    return null
  }

  return (
    <div>
      {timestamps.map((timestamp, index) =>
        timestamp?.label && timestamp?.time ? (
          <Timestamp start={timestamp.time} key={index}>
            {timestamp.label}
          </Timestamp>
        ) : null,
      )}
    </div>
  )
}
