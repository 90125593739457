import * as styles from './DraftViewer.module.scss'

import React, { useMemo, useState } from 'react'
import classNames from 'classnames'

import CardImage from 'components/cards/CardImage'
import CardHover from 'components/cards/CardHover'

import Controls from './Controls'

interface Props {
  packsPickedFrom: {
    pack: number
    pick: number
    cards: { name: string; emphasized?: boolean }[]
  }[]
}

export const DraftViewer: React.FC<Props> = (props) => {
  const { packsPickedFrom } = props

  const [pick, setPick] = useState(0)
  const [showPicks, setShowPicks] = useState(false)

  const allPickedCards = useMemo(
    () =>
      packsPickedFrom
        .flatMap((pack) => pack.cards.filter((card) => card.emphasized))
        .map((card) => card.name),
    [packsPickedFrom],
  )

  const pickedCards = useMemo(
    () => allPickedCards.slice(0, pick),
    [allPickedCards, pick],
  )

  const pack = useMemo(() => packsPickedFrom[pick], [packsPickedFrom, pick])

  return (
    <div>
      <div className={styles.controls}>
        <Controls
          pick={pick}
          setPick={setPick}
          packsPickedFrom={packsPickedFrom}
          showPicks={showPicks}
          setShowPicks={setShowPicks}
        />
      </div>

      <div className={styles.cards}>
        {pack.cards.map((card, index) => (
          <div
            key={index}
            className={classNames(styles.card, {
              [styles.picked]: showPicks && card.emphasized,
              [styles.notPicked]: showPicks && !card.emphasized,
            })}
          >
            <CardImage>{card.name}</CardImage>
          </div>
        ))}
      </div>

      {pickedCards.length > 0 && (
        <div>
          <h4 className={styles.pickedHeading}>Picked Cards</h4>
          <div className={styles.accumulatedPicks}>
            {pickedCards.map((cardName, index) => (
              <div key={index}>
                <CardHover card={cardName}>
                  <CardImage>{cardName}</CardImage>
                </CardHover>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
