import * as styles from './PodcastContent.module.css'

import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { PageProps } from 'gatsby'

import Markdown from 'src/components/markdown/Markdown'

import { Timestamps } from './timestamps/Timestamps'
import { Cards } from './cards/Cards'

interface Props {
  podcast: NonNullable<
    PageProps<Queries.PodcastTemplateQuery>
  >['data']['podcast']
  initialView?: 'cards' | 'timestamps'
}

export const PodcastContent: React.FC<Props> = (props) => {
  const { podcast } = props

  const episode = podcast?.frontmatter
  if (episode == null) {
    throw new Error('Missing frontmatter on podcast episode.')
  }

  const [activeView, setActiveView] = useState<null | 'timestamps' | 'cards'>(
    props.initialView ?? null,
  )

  // Update the state and the URL manually to prevent the player from
  // re-rendering and stopping.
  const navigateTo = useCallback(
    (view: null | 'timestamps' | 'cards') => {
      window.history.replaceState(
        {},
        '',
        `${window.location.protocol}//${window.location.host}/podcast/${
          episode.episodeSlug
        }/${view != null ? `${view}/` : ''}`,
      )

      setActiveView(view)
    },
    [episode.episodeSlug],
  )

  return (
    <div className={styles.container}>
      <nav role="navigation" className={styles.navigation}>
        <button
          className={classNames(styles.tab, {
            [styles.active]: activeView === null,
          })}
          onClick={() => {
            navigateTo(null)
          }}
        >
          Notes
        </button>
        <button
          className={classNames(styles.tab, {
            [styles.active]: activeView === 'timestamps',
          })}
          onClick={() => {
            navigateTo('timestamps')
          }}
        >
          Timestamps
        </button>
        <button
          disabled={podcast?.frontmatter?.cards == null}
          className={classNames(styles.tab, {
            [styles.active]: activeView === 'cards',
          })}
          onClick={() => {
            navigateTo('cards')
          }}
        >
          Cards Mentioned
        </button>
      </nav>

      {activeView === null && (
        <div>
          {podcast?.body != null && <Markdown>{podcast.body}</Markdown>}
        </div>
      )}

      {activeView === 'timestamps' && (
        <div className={styles.content}>
          {episode.timestamps != null && (
            <Timestamps timestamps={episode.timestamps} />
          )}
        </div>
      )}

      {activeView === 'cards' && (
        <div className={styles.content}>
          {episode.cards?.groups != null ? (
            <Cards cards={episode.cards} />
          ) : null}
        </div>
      )}

      {episode.annotationCredit?.name != null && (
        <div className={styles.annotationCredit}>
          <div className={styles.annotationCreditContent}>
            Thank you to{' '}
            <a href={episode.annotationCredit.link ?? undefined}>
              {episode.annotationCredit.name}
            </a>{' '}
            for annotating this episode!
          </div>
        </div>
      )}
    </div>
  )
}
