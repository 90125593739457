import * as styles from './EpisodeNavigation.module.scss'

import React from 'react'
import { Link } from 'gatsby'

interface Props {
  previousEpisode?: {
    title: string
    episodeSlug: string | null
  } | null
  nextEpisode?: {
    title: string
    episodeSlug: string | null
  } | null
}

export const EpisodeNavigation: React.FC<Props> = (props) => {
  const { previousEpisode, nextEpisode } = props

  if (previousEpisode == null && nextEpisode == null) {
    return null
  }

  return (
    <div className={styles.container}>
      {previousEpisode != null ? (
        <Link
          to={`/podcast/${previousEpisode.episodeSlug}/`}
          className={styles.navigationButton}
        >
          <div>&larr;</div>
          <div>
            <div>Previous Episode</div>
            <div className={styles.navigationButtonTitle}>
              {previousEpisode.title}
            </div>
          </div>
        </Link>
      ) : (
        <div className={styles.placeholder}></div>
      )}

      <Link className={styles.navigationButton} to="/podcast">
        All Episodes
      </Link>

      {nextEpisode != null ? (
        <Link
          to={`/podcast/${nextEpisode.episodeSlug}/`}
          className={styles.navigationButton}
        >
          <div>
            <div>Next Episode</div>
            <div className={styles.navigationButtonTitle}>
              {nextEpisode.title}
            </div>
          </div>
          <div>&rarr;</div>
        </Link>
      ) : (
        <div className={styles.placeholder}></div>
      )}
    </div>
  )
}
